import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  analyticsTrack,
  INITIATE_EXPERIMENT,
  planSelectionVersion,
} from '@pumpkincare/analytics';
import {
  getPlanVersion,
  getProductCanUpsellPrevent,
  getProductHasPrevent,
  isPrevent4,
  usePetPlanData,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuotePolicyState,
  getQuotePolicyZipCode,
  getQuoteVetId,
  useGoToCheckout,
  useMutatePetPlan,
  useQuote,
} from '@pumpkincare/quotes';
import {
  capitalize,
  DOG,
  getIsLoggedIn,
  PET_AGE_OPTIONS,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  KeyboardArrowDown,
  LegalBody,
  Modal,
  NotifyMessage,
  Testimonials,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity/selectors';
import { getQuotesActiveId } from '../../../quotes/selectors';
import goToAddNewPet from '../../thunks/go-to-add-new-pet';
import CompareTable from '../compare-table';
import MoneyBackGuarantee from '../money-back-guarantee';
import NotCovered from '../not-covered';
import PepPicker from '../pep-picker/pep-picker';
import Pep3PickerDetails from '../pep3-picker-details';
import Pep4PickerDetails from '../pep4-picker-details';
import PlanCalculator from '../plan-calculator';
import PlanCalculatorLegacy from '../plan-calculator-legacy';
import PlanHowItWorks from '../plan-how-it-works';
import PlanNotCovered from '../plan-not-covered';
import PlanPepUpsell from '../plan-pep-upsell/plan-pep-upsell';
import PlanRoyalty from '../plan-royalty';
import PlanStatistics from '../plan-statistics';
import PlanVetModule from '../plan-vet-module';
import PlanWhatsCovered from '../plan-whats-covered';
import PumpkinDifference from '../pumpkin-difference';
import QuoteEditModal from '../quote-edit-modal';
import SlideOutPanel from '../slide-out-panel';
import StickyCta from '../sticky-cta';
import CoverageDetails from './coverage-details';
import Picker from './picker';
import WaveDelimiter from './wave-delimiter';

import styles from './ecomm-view-v2.module.css';

function EcommViewV2({ planPageContent }) {
  const isLoggedIn = getIsLoggedIn();

  const goToCheckout = useGoToCheckout(Paths.Checkout);

  const {
    calculator,
    coverageDetails: planCoverageDetails,
    pepCallout,
    howItWorks,
    whyPetInsurance,
    preExConditions,
    royalty,
    headerSplit,
    pickerInfo: { imageUrl: pickerImage },
    vetVisits,
  } = planPageContent;

  const {
    punks594BreedSpeciesOnPlanPage,
    suppressMaine,
    punks646PlanPageOrder,
    punks363EditSummary,
    whatsCoveredModule,
    notCoveredPepUpsellModules,
    statsPreExistingConditions,
    dynamicCalculator,
    profileModule,
  } = useFlags();

  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const identityId = useSelector(getIdentityId);

  const { data: quoteData } = useQuote();
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const policyZipcode = getQuotePolicyZipCode(quoteData);
  const policyState = getQuotePolicyState(quoteData);
  const vetId = getQuoteVetId(quoteData);

  // forcing to fetch all necessary data here so it's available in cache when we render child components
  const { data: petPlanData } = usePetPlanData({
    petId: activePet?.id,
    identityId,
    vetId,
  });
  const {
    activeValues,
    insurancePicker: { activePrice, recommendations },
    coverageDetails,
  } = petPlanData;

  const { data: userData } = useUssr();
  const userId = getUserId(userData);

  const { data: planData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    vetId
  );
  const versionNumber = getPlanVersion(planData);

  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const { mutateAsync: mutatePetPlan } = useMutatePetPlan();

  const suppressMainePepFlyOut = policyState === 'ME' && suppressMaine;

  const isDog = activePet.petBreedSpecies === DOG;
  const testimonialImage = isDog
    ? '/assets/images/backgrounds/simon.png'
    : '/assets/images/backgrounds/cat-in-crate-1440x593.png';

  const testimonialText = isDog
    ? `“Cecily was better cared for because I didn’t haggle over staying in the hospital because of cost.”`
    : `“As a pet owner you never think the unexpected illness will happen, but having Pumpkin made this painful situation so much less stressful.”`;

  const addToCartButtonText = 'Select This Plan';
  const testimonialAuthor = isDog ? 'Victoria Phillips' : 'Kelly McGarrity';
  const testimonialPet = isDog ? 'Cecily' : 'Gingersnap';
  const [isSlideOpen, setSlideOpen] = useState(false);
  const [addAPetScreen, showAddAPetScreen] = useState(false);
  const [stickyCtaVisibility, setStickyCtaVisibility] = useState(false);
  const [isCompareTableOpen, toggleCompareTableOpen] = useBooleanInput(false);
  const [isEditQuoteOpen, toggleEditQuoteOpen] = useBooleanInput(false);
  const postPickerRef = useRef();

  const {
    copay: { id: copayId, value: copayValue },
    annual_limit: { id: annualLimitId, value: annualLimitValue },
    deductible: { id: deductibleId, value: deductibleValue },
  } = recommendations || {};

  const {
    copay: copayActive,
    annual_limit: annualLimitActive,
    deductible: deductibleActive,
  } = activeValues;

  const calculatorValues = {
    copay: dynamicCalculator ? copayActive : copayValue,
    annualLimit: dynamicCalculator ? annualLimitActive : annualLimitValue,
    deductible: dynamicCalculator ? deductibleActive : deductibleValue,
  };

  useEffect(() => {
    document.body.style.overflow = isSlideOpen ? 'hidden' : 'unset';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  }, [isSlideOpen]);

  useEffect(() => {
    function handleScroll() {
      if (postPickerRef.current) {
        const { offsetTop } = postPickerRef.current;

        if (window.scrollY > offsetTop) {
          setStickyCtaVisibility(true);
        } else {
          setStickyCtaVisibility(false);
        }
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [postPickerRef]);

  // segment analytics for plan page
  useEffect(() => {
    if (annualLimitId && versionNumber) {
      analyticsTrack(
        planSelectionVersion(
          annualLimitId,
          copayId,
          deductibleId,
          versionNumber,
          vetId
        )
      );
    }
  }, [annualLimitId, copayId, deductibleId, versionNumber, vetId]);

  useEffect(() => {
    analyticsTrack({
      category: 'Personalized Breed Definitions',
      event: INITIATE_EXPERIMENT,
      label: punks594BreedSpeciesOnPlanPage ? 'b_test' : 'a_control',
    });
  }, [punks594BreedSpeciesOnPlanPage]);

  useEffect(() => {
    analyticsTrack({
      category: 'Plan Page Edit Summary',
      event: INITIATE_EXPERIMENT,
      label: punks363EditSummary ? 'b_test' : 'a_control',
    });
  }, [punks363EditSummary]);

  useEffect(() => {
    analyticsTrack({
      category: 'Whats Covered',
      event: INITIATE_EXPERIMENT,
      label: whatsCoveredModule ? 'b_test' : 'a_control',
    });
  }, [whatsCoveredModule]);

  useEffect(() => {
    analyticsTrack({
      category: 'Not Covered and PEP Upsell Modules',
      event: INITIATE_EXPERIMENT,
      label: notCoveredPepUpsellModules ? 'b_test' : 'a_control',
    });
  }, [notCoveredPepUpsellModules]);

  useEffect(() => {
    analyticsTrack({
      category: 'Stats Module',
      event: INITIATE_EXPERIMENT,
      label: statsPreExistingConditions ? 'b_test' : 'a_control',
    });
  }, [statsPreExistingConditions]);

  useEffect(() => {
    analyticsTrack({
      category: 'Dynamic Calculator',
      event: INITIATE_EXPERIMENT,
      label: dynamicCalculator ? 'b_test' : 'a_control',
    });
  }, [dynamicCalculator]);

  useEffect(() => {
    analyticsTrack({
      category: 'Calculator Order',
      event: INITIATE_EXPERIMENT,
      label: punks646PlanPageOrder ? 'b_test' : 'a_control',
    });
  }, [punks646PlanPageOrder]);

  useEffect(() => {
    analyticsTrack({
      category: 'Profile Module',
      event: INITIATE_EXPERIMENT,
      label: profileModule ? 'b_test' : 'a_control',
    });
  }, [profileModule]);

  function handleAddToCart() {
    analyticsTrack({
      category: 'Select Plan',
      event: 'Click Select Plan',
      label: 'Select Plan',
    });

    if (isLoggedIn && activePet.hasPrevent) {
      goToCheckout();
    } else {
      window.scrollTo(0, 0);
      setSlideOpen(true);
    }
  }

  function handlePreventChange() {
    analyticsTrack({
      event: activePet.hasPrevent
        ? 'Remove prevent from plan'
        : 'Add prevent to plan',
      category: 'Plan Page',
    });

    mutatePetPlan({
      pet: { ...activePet, hasPrevent: !activePet.hasPrevent },
      plan: planData,
    }).then(() => {
      if (isLoggedIn) {
        goToCheckout();
      }
    });
  }

  function handleOnAdd() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click Add To Plan',
      label: 'Add Preventive Essentials',
    });

    if (productHasPrevent) {
      handlePreventChange();
    } else if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleOnDecline() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click No Thanks',
      label: 'Add Preventive Essentials',
    });

    if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleBackDropClose() {
    showAddAPetScreen(false);
    setSlideOpen(false);
  }

  function handleAddPetClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click Add Another Pet',
      label: 'Want to add another pet?',
    });

    dispatch(goToAddNewPet());
    dispatch(push(Paths.Intro));
  }

  function handleGoToCheckoutClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click No Thanks',
      label: 'Want to add another pet?',
    });

    goToCheckout();
  }

  return (
    <>
      {isEditQuoteOpen ? <QuoteEditModal onClose={toggleEditQuoteOpen} /> : null}

      {punks363EditSummary ? (
        <div className={styles.topBanner}>
          <LegalBody className={styles.topBannerText}>
            {`${activePet.petBreedName} • ${
              PET_AGE_OPTIONS.find(({ value }) => value === activePet.petAge).label
            } • ${capitalize(activePet.petGender)} • ${policyZipcode} `}

            {quoteData.vet.vet_name ? (
              <span className={styles.vetName}>
                {`•  ${quoteData.vet.vet_name}`}
              </span>
            ) : null}

            {punks363EditSummary ? (
              <button
                className={classNames(ButtonStyles.cta, styles.editQuoteButton)}
                onClick={toggleEditQuoteOpen}
              >
                EDIT
              </button>
            ) : null}
          </LegalBody>
        </div>
      ) : null}

      <StickyCta
        pet={activePet}
        price={activePrice}
        onCtaClick={handleAddToCart}
        onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
        visibility={stickyCtaVisibility}
      />

      {isCompareTableOpen ? (
        <Modal onClose={toggleCompareTableOpen}>
          <CompareTable onClose={toggleCompareTableOpen} />

          <div className={styles.compareFooterSection}>
            <button
              onClick={toggleCompareTableOpen}
              className={classNames(ButtonStyles.cta)}
            >
              Back to Plan Details
            </button>
          </div>
        </Modal>
      ) : null}

      <Picker
        buttonText={addToCartButtonText}
        data-testid='select-phi'
        headerSplit={headerSplit}
        image={pickerImage}
        onAddToCart={handleAddToCart}
      />

      <div ref={postPickerRef} />

      <SlideOutPanel
        classes={{ container: styles.slideOutContainer }}
        show={isSlideOpen}
        onClose={handleBackDropClose}
      >
        <div className={styles.notifyMessage}>
          {activePet.hasPrevent ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Preventive Essentials Added to Cart`}
            />
          ) : null}

          {!activePet.hasPrevent && productHasPrevent && !addAPetScreen ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Insurance Added to Cart`}
            />
          ) : null}
        </div>

        <div className={styles.slideOutContent}>
          <div className={styles.slideOutBody}>
            {productHasPrevent === false && !suppressMainePepFlyOut ? (
              <div className={styles.unavailable}>
                <img src='/assets/images/illustrations/multipet/map.png' alt='' />

                <div>
                  <Body2 isBold className={styles.pepPack}>
                    Preventive Essentials Pack is not available in your state at this
                    time.
                  </Body2>

                  <LegalBody className={styles.bodyText}>
                    You’ll still get pawesome accident & illness coverage for{' '}
                    {activePet?.petName} with your insurance. You just won’t have the
                    option to get yearly preventive care covered (e.g. vaccines,
                    wellness exam fees, heartworm or fecal tests). Preventive
                    Essentials is not insurance, but an optional wellness package
                    available in some states.
                  </LegalBody>
                </div>
              </div>
            ) : null}

            {!productHasPrevent ||
            ((activePet.hasPrevent || addAPetScreen) && !isLoggedIn) ? (
              <div>
                <img
                  src={'/assets/images/circle-cat-dog.png'}
                  className={styles.topIconDogCat}
                  alt=''
                />

                <div className={styles.newPetBody}>
                  <span
                    className={classNames(Typography.h3, styles.newPetBodyTitle)}
                  >
                    Want to add another pet?
                  </span>

                  <Body1 className={styles.newPetBodyText}>
                    We have a pawsome 10% discount for covering multiple fur babies.
                  </Body1>
                </div>

                <div className={styles.newPetButtonSection}>
                  <button
                    data-testid='add-a-pet'
                    className={classNames(ButtonStyles.primary, styles.btnStyle)}
                    onClick={handleAddPetClick}
                  >
                    Add Another Pet
                  </button>

                  <button
                    data-testid='no-add-pet'
                    className={classNames(ButtonStyles.secondary, styles.btnStyle)}
                    onClick={handleGoToCheckoutClick}
                  >
                    No Thanks - Go to Checkout
                  </button>
                </div>
              </div>
            ) : (
              <>
                <img
                  alt=''
                  src={'/assets/images/iconImages/design-star.png'}
                  className={styles.topIconStar}
                />

                <PepPicker
                  plan={planData}
                  quoteData={quoteData}
                  activePet={activePet}
                  onAdd={handleOnAdd}
                  onRemove={handleOnDecline}
                />

                <div className={styles.blueCircle}>
                  <KeyboardArrowDown className={styles.arrowIcon} />
                </div>

                {isPrevent4(planData) && activePet ? (
                  <Pep4PickerDetails
                    plan={planData}
                    activePet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                ) : (
                  <Pep3PickerDetails
                    pet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </SlideOutPanel>

      {punks646PlanPageOrder ? (
        <WaveDelimiter
          style={{ zIndex: 0 }}
          className={styles.wayFinding}
          id={'contentStart'}
          line
          onArrowClick={() => {
            document
              .getElementById('contentStart')
              .scrollIntoView({ behavior: 'smooth' });
          }}
        />
      ) : null}

      {punks646PlanPageOrder ? null : (
        <PlanCalculatorLegacy data={calculator} values={calculatorValues} />
      )}

      {royalty.enabled ? <PlanRoyalty {...royalty} /> : null}

      {whatsCoveredModule ? (
        <PlanWhatsCovered
          coverageDetails={planCoverageDetails}
          onCompareClick={toggleCompareTableOpen}
        />
      ) : null}

      {notCoveredPepUpsellModules && policyState !== 'ME' ? (
        <PlanNotCovered
          petBreedSpecies={activePet.petBreedSpecies}
          petAge={activePet.petAge}
        />
      ) : null}

      {notCoveredPepUpsellModules &&
      productCanUpsellPrevent &&
      policyState !== 'ME' ? (
        <PlanPepUpsell
          petBreedSpecies={activePet.petBreedSpecies}
          pepCallout={pepCallout}
        />
      ) : null}

      {whatsCoveredModule ? null : (
        <CoverageDetails activePet={activePet} data={coverageDetails} />
      )}

      {policyState === 'ME' ? <NotCovered plan={planData} /> : null}

      <PlanVetModule
        vetName={quoteData.vet.vet_name}
        pet={activePet}
        icon={vetVisits}
      />

      <WaveDelimiter bluesCluesToHuckleberry />

      {punks646PlanPageOrder ? (
        <>
          <PlanCalculator data={calculator} values={calculatorValues} />

          <WaveDelimiter benjiBlue />
        </>
      ) : null}

      <PlanHowItWorks howItWorks={howItWorks} />

      {statsPreExistingConditions ? (
        <PlanStatistics
          whyPetInsurance={whyPetInsurance}
          preExConditions={preExConditions}
          petBreedSpecies={activePet.petBreedSpecies}
          state={policyState}
        />
      ) : (
        <>
          <Testimonials image={testimonialImage}>
            <blockquote style={{ margin: 0 }}>
              <h6>{testimonialText}</h6>

              <h6 style={{ fontSize: '18px' }}>
                {testimonialAuthor},
                <br />
                {testimonialPet}’s Mom
              </h6>
            </blockquote>
          </Testimonials>

          <PumpkinDifference />
        </>
      )}
      {policyState === 'NY' ? null : (
        <MoneyBackGuarantee policyState={policyState} />
      )}
    </>
  );
}

export default EcommViewV2;
